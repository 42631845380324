/* فونت‌های سفارشی */
@font-face {
  font-family: 'IRANSansWeb';
  src: url('/public/assets/fonts/IRANSansWeb/IRANSansWeb.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Estedad-Regular';
  src: url('/public/assets/fonts/Estedad/Estedad-Regular.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'subset-IRANSansWebFaNum';
  src: url('/public/assets/fonts/IRANSansWeb/subset-IRANSansWebFaNum.woff2') format('woff2');
  font-display: swap;
}


/* حذف margin و box-sizing برای تمام المان‌ها */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* لینک‌ها بدون زیرخط */
a {
  text-decoration: none;
}

/* استایل‌های اسکرول */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #555;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
  transition: 0.3s;
}

/* جلوگیری از انتخاب تصاویر */
img {
  pointer-events: none;
}

/* استایل‌های ریسپانسیو */
@media screen and (max-width: 400px),
       only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  body {
    overflow-x: hidden;
  }
}
